<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                   <div class="row">
                       <div class="col l-6 m-6 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="parentName" @md-selected="getParentSelected" :md-options="parents" @md-changed="getParents"  @md-opened="getParents" :class="{'md-invalid': submitted && $v.entity.parentId.$error }">
                                    <label>Nhóm cha</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.groupName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.parentId.required">Vui lòng chọn nhóm cha</span>
                                </md-autocomplete>
                                <md-button @click="openParent()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>

                            <md-field :class="{'md-invalid': submitted && $v.entity.groupCode.$error }">
                                <label for="groupCode">Mã nhóm phương tiện</label>
                                <md-input name="groupCode" v-model="entity.groupCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.groupCode.required">Vui lòng nhập mã nhóm phương tiện</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.groupName.$error }">
                                <label for="groupName">Tên nhóm phương tiện</label>
                                <md-input name="groupName" v-model="entity.groupName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.groupName.required">Vui lòng nhập tên nhóm phương tiện</span>
                            </md-field>

                            <div class="form-control">
                                <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <parentList ref="parentList" @close="closeParent"/>
   </div>
</template>
<script>
    import vehicleGroupService from '../../../api/vehicleGroupService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import parentList from '../../../components/popup/_VehicleParentGroup.vue'

    export default ({
        components: {
            parentList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật nhóm phương tiện'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               entity: { id: 0, parentId: 0, groupCode: '', isVehicle: false, groupName: '', isActive: true },
               parents: [],
               parentName: 'Nhóm cha'
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật nhóm phương tiện';
               this.getById();
            }
            else{
               this.title = 'Thêm mới nhóm phương tiện';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            closeParent(item){
                this.parentName = item.groupName;
                this.entity.parentId = item.id;
                this.$refs.parentList.close();
            },

            getParentSelected(val){
                this.entity.parentId = val.groupName;
                this.parentName = val.ParentName;
            },

            openParent(){
                this.$refs.parentList.open(false);
            },

            getParents(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, isVehicle: false, code: '', name:  searchTerm };
                vehicleGroupService.getParents(search).then((response) => {
                    if(response.statusCode == 200){
                        this.parents = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                vehicleGroupService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                vehicleGroupService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/forklift-group');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                vehicleGroupService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.parentName = response.data.parent.groupName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/forklift-group');
            }
        },
        watch: { 
            parentName: function (val) { 
                if(val == ''){
                    this.entity.parentId = 0;
                    this.parentName = "Nhóm cha";
                }
            }
        },
        validations: {
            entity: {
                parentId: { required },
                groupCode: { required },
                groupName: { required },
            }
       }
     })

</script>
